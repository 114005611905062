import * as CryptoJS from 'crypto-js';

export const encryptData = (data) => {
  const key1 = 'N!@G@R@+B0++L!NGN!@G@R@+B0++L!NG';
  const key2 = '1111000111001101';
  var key = CryptoJS.enc.Utf8.parse(key1);
  var iv = CryptoJS.enc.Utf8.parse(key2);
  var encrypted = CryptoJS.AES.encrypt(data, key,
    {
      keySize: 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
    }).toString();
  return encrypted;
}

export const decryptData = (encryptedData) => {
  var key = CryptoJS.enc.Utf8.parse('N!@G@R@+B0++L!NGN!@G@R@+B0++L!NG');
  var iv = CryptoJS.enc.Utf8.parse('1111000111001101');
  var decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    keySize: 32,
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8);
  return decrypted;
}