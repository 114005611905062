import React, { Component } from 'react';
import { CallApi } from '../../services/service';
import { validator } from '../../validator';
import { CONFIG } from '../../configuration/config';
import * as ROUTES from '../../constants/routes';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import LanguageUtils from '../../utils/LanguageUtils';
import { setData, getData, removeData } from "../../containers/storageHandler";
import AlertModal from '../../common/modals/AlertModal';

import {
  isFormValid,
  resetValidators,
  updateValidators,
  displayValidationErrors
} from '../../containers/formValidator';

class LandingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    resetValidators(validator);
  }

  //   var validators= '';
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    updateValidators(event.target.name, event.target.value, validator);
  };

  handleSubmit = event => {
    event.preventDefault();
    const loginObj = {
      username: this.state.username,
      password: this.state.password
    };
    const langType = LanguageUtils.getLangType(this.props);
    spinnerService.show(spinnerName.LOGIN_SPINNER);
    CallApi('POST', CONFIG.authorizeSso, {}, loginObj, langType, "authorizeLogin", this);
  };

  authorizeLogin = (response) => {
    if (response.result.status === true) {
      setData('auth', response.result.token);
      setData('isLogin', true);
      removeData('configureData');
      removeData('pageName');
      this.props.history.push(ROUTES.CONFIGURATION);
    } else {
      AlertModal.showAlert(response.message);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-page">
          <div className="body-container">
            <section className="login-form-section">
              <div className="form-wrapper">
                <form
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                  className="login-form white-box"
                >
                  <div className="text-center">
                    <img
                      src={require('../../assets/images/orange-logo.png')}
                      alt=""
                      className="form-logo"
                      width="159"
                    />
                  </div>
                  <h3 className="form-title">
                    Admin <strong>Login</strong>
                  </h3>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleInputChange}
                      placeholder="User name"
                      autoComplete="off"
                      autoFocus={true}
                    />
                    {displayValidationErrors('username', validator)}
                  </div>
                  <br />
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      placeholder="Password"
                    />
                    {displayValidationErrors('password', validator)}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="Login"
                      className="btn btn-submit"
                      disabled={!isFormValid(validator)}
                      value="Login"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LandingComponent;
