import React, { Component } from "react";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import { connect } from "react-redux";
import propTypes from "prop-types";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import Header from "../../common/Header";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import { setData, getData, removeData } from "../../containers/storageHandler";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class CheckOutExitComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signData: "",
      modalIsOpen: false,
      rating: 0,
      feedBackText: "",
      pageNumber: 1,
      configurationData: getData("configureData"),
      exitLane: "",
      pageNameList: [
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
      ]
    };
  }

  componentWillMount() {
    removeData('isBOL');
    if (this.state.configurationData.laneData.length === 1) {
      this.handleLane(1)
      // setData('laneIndex', 1);
      // setData('isExitFlow', 1);
      // this.props.history.push(ROUTES.CHECKOUTGATEPASS);
    }
    else if (this.state.configurationData.skipLaneSelection === "Y"){
      this.handleLane(this.state.configurationData.laneNo);
      // setData('laneIndex', this.state.configurationData.laneNo);
      // setData('isExitFlow', 1);
      // this.props.history.push(ROUTES.CHECKOUTGATEPASS);
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  back() {
    this.props.history.goBack();
  }

  handleLane(lane) {
    const configureData = getData('configureData')
    configureData.laneNo = lane;
    configureData.showFacilityAndLane = true;
   
    setData('configureData', configureData)
    setData('laneIndex', lane);
    setData('isExitFlow', 1);
    this.props.history.push(ROUTES.CHECKOUTGATEPASS);
    // if (this.state.configurationData.laneData.length > 1) {
    //   this.handleSubmit(lane-1);
    // }
  }

  handleSubmit(index) {
    // if (this.props.location.state.checkFlow === 1) {
    setData('isExitFlow', 1);
    this.props.history.push(
      {
        pathname: ROUTES.CHECKOUTGATEPASS,
        state: {
          index: index
        }
      });
  }

  processBar() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.props.location.state.checkFlow === 1) {
      return (
        <PageProgressComponent
          pageName={LANGCONSTANT[langType].LABELS.CheckOut}
          pageNumber={this.state.pageNumber}
          totalPage={5}
          pageNameList={
            [
              LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
              LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
              LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
              LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
              LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
            ]
          }
        />
      );
    } else {
      return (
        <PageProgressComponent
          pageName={LANGCONSTANT[langType].LABELS.CheckOut}
          pageNumber={1}
          totalPage={2}
          pageNameList={[LANGCONSTANT[langType].LABELS.ExitGate, LANGCONSTANT[langType].LABELS.DeliveryDetails]}
        />
      );
    }
  }

  render() {
    const { rating } = this.state;
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div>
          <div className="body-container">
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container container-890 white-box">
                    <div style={{ textAlign: "center" }}>
                      <h4>{LANGCONSTANT[langType].LABELS.BillofLead}</h4>
                      <span>
                        {this.state.configurationData.laneData.map((data, index) => {
                          var dataLaneText = data.lane_text;
                          var spanishLaneText = dataLaneText.replace("Lane", "Carril")
                          return (
                            <input
                              key={data.lane_no}
                              type="button"
                              className="lane-btn btn"
                              value={langType === 'ESP' ? spanishLaneText : data.lane_text}
                              onClick={() => this.handleLane(data.lane_no)}
                            />
                          );
                        })}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <span>
                        <a href="javaScript:void(0)" onClick={() => this.openModal()} data-target="#myModal">
                          <u>{LANGCONSTANT[langType].LABELS.TermsAndConditions}</u>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <div className="progress-bar-bottom">{this.processBar()}</div> */}
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal">
              <h2 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].LABELS.TermsAndConditions}</h2>
              <ol>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}</li>
              </ol>
              <center>
                <button onClick={() => this.closeModal()}>{LANGCONSTANT[langType].BUTTONS.Ok}</button>
              </center>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CheckOutExitComponent.propTypes = {
  dispatch: propTypes.func.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckOutExitComponent);
