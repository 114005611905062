import React, { Component } from 'react';
import Header from "../../common/Header";
import * as guardAction from "../../redux/actions/guardAction";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import { connect } from 'react-redux';
import { setData, getData, removeData } from "../../containers/storageHandler";
import * as ROUTES from "../../constants/routes";

class CheckoutFlowSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configurationData: getData("configureData")
    };
  }

  componentWillMount() {
    const dataObj = {
      guard: true
    };
    this.props.dispatch(guardAction.guardData(dataObj));
    removeData('isExitFlow');
    removeData('isBOL');
    removeData('plantType');
  }

  handleFlow(selection) {
    const plantType = this.state.configurationData.plantType;
    const skipInbound = this.state.configurationData.skipInbound;
    setData('plantType', plantType)
    // if (this.state.configurationData.laneData.length === 1) {
    //   setData('isExitFlow', 1);
    //   this.props.history.push(ROUTES.CHECKOUTGATEPASS);
    // } else {
    //   this.props.history.push({
    //     pathname: ROUTES.EXITGATE,
    //     state: {
    //       checkFlow: 1
    //     }
    //   });
    // }

    switch (selection) {
      case 1:
        setData('isBOL', 'Yes');
        if (this.state.configurationData.laneData.length === 1) {
          setData('laneIndex', 1);
          setData('isExitFlow', 1);
          this.props.history.push(ROUTES.CHECKOUTGATEPASS);
        } else {
          this.props.history.push({
            pathname: ROUTES.EXITGATE,
            state: {
              checkFlow: 1
            }
          });
        }
        break;
      case 2:
        setData('isBOL', 'No');
        if (skipInbound === 'N') {
          if (this.state.configurationData.laneData.length === 1) {
            setData('laneIndex', 1);
            setData('isExitFlow', 1);
            this.props.history.push(ROUTES.CHECKOUTGATEPASS);
            // this.props.history.push(ROUTES.EXITINTERMEDIATE);
          } else {
            this.props.history.push({
              pathname: ROUTES.EXITGATE,
              state: {
                checkFlow: 1
              }
            });
          }
        } else if (skipInbound === 'Y') {
          if (this.state.configurationData.laneData.length === 1) {
            setData('laneIndex', 1);
            setData('isExitFlow', 2);
            // this.props.history.push(ROUTES.CHECKOUTGATEPASS);
            this.props.history.push(ROUTES.EXITINTERMEDIATE);
          } else {
            this.props.history.push({
              pathname: ROUTES.EXITGATE,
              state: {
                checkFlow: 2
              }
            });
          }
        }
        break;
      default:
      // console.log('wrong selection');
    }
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Header history={this.props.history} />
        <div className="body-container">
          <section>
            <div className="language-section text-center">
            <h4>{LANGCONSTANT[langType].STRINGS.SelectBOL}</h4>
              <div className="btn-wrapper">
                <span>
                  <input
                    type="submit"
                    className="btn btn-disable"
                    value={LANGCONSTANT[langType].BUTTONS.Yes}
                    onClick={() => this.handleFlow(1)}
                  />
                </span>
                <span>
                  <input
                    type="submit"
                    className="btn btn-disable"
                    value={LANGCONSTANT[langType].BUTTONS.No}
                    onClick={() => this.handleFlow(2)}
                  />
                </span>
              </div>
            </div>
          </section>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckoutFlowSelection);
