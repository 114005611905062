export const spinnerName = {
  OTP_SPINNER: 'otpSpinner',
  LOGIN_SPINNER: 'loginSpinner',
  CONFIG_SPINNER: 'configSpinner',
  PROFILE_SPINNER: 'profileSpinner',
  TRAILER_SPINNER: 'trailerSpinner',
  YOUR_TRIPS_SPINNER: 'yourTripsSpinner',
  PROCESS_SPINNER: 'processSpinner',
  ADD_TRIP_SPINNER: 'addTripSpinner'
};
