import React, { Component } from "react";
import {
  BrowserRouter as Router,
  // HashRouter,
  // Route,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import LandingComponent from "../components/landing/LandingComponent";

import * as ROUTES from "../constants/routes";
import ConfigureComponent from "../components/configure/ConfigureComponent";
import CheckinGatePassComponent from "../components/CheckinGatePass/CheckinGatePassComponent";
import CheckinAppointmentComponent from "../components/checkinSelection/CheckinAppointmentComponent";
import CheckinDeliveryDetail from "../components/checkinDeliveryDetails/CheckinDeliveryDetail";
import TermsAndConditionsComponent from "../components/termsAndConditions/TermsAndConditionsComponent";
import CheckoutGatePassComponent from "../components/checkoutGatePassComponent/CheckoutGatePassComponent";
import ConfirmTrailerDetailCompoent from "../components/confirmTrailerDetailComponent/ConfirmTrailerDetailComponent";
import CheckOutSealComponent from "../components/checkoutSeal/CheckOutSealComponent";
import CheckoutAcknowledgementComponent from "../components/checkoutAcknowledgement/CheckoutAcknowledgementComponent";
import CheckOutSignComponent__Typed from "../components/checkoutSignComponent/CheckOutSignComponent__Typed";
import CheckOutSignComponent__Written from "../components/checkoutSignComponent/CheckOutSignComponent__Written";
import DropTrailerEnterDetails from "../components/dropTrailerEnterDetails/DropTrailerEnterDetails";
import DropTrailerResultComponent from "../components/dropTrailerResult/DropTrailerResultComponent";
import DeliveryAndReturnComponent from "../components/deliveryAndReturn/DeliveryAndReturnComponent";
import DeliveryAndReturnEnterDetailComponent from "../components/deliveryAndReturnEnterDetails/DeliveryAndReturnEnterDetailComponent";
import CheckoutExitComponent from "../components/checkoutExit/CheckoutExitComponent";
import LanguageComponent from "../components/languageComponent/LanguageComponent";
import { SpinnerComponent as Spinner } from "../common/spinner/SpinnerComponent";
import CheckoutFlowSelection from "../components/checkoutFlowSelection/CheckoutFlowSlection";
import ExitFlowIntermediate from "../components/ExitFlowIntermediate/ExitFlowIntermediate";
import { spinnerName } from "../constants/data";
import { getData } from "../containers/storageHandler";
import AlertModal from "../common/modals/AlertModal";
import {
  ProtectedRoute,
  PublicRoute,
  CheckinConfigureRoute,
  CheckOutConfigureRoute,
  CheckIsConfigured,
  CheckOutConfigureExitRoute,
  CheckOutExitRoute,
  LanguageConfigureRoute,
} from "./protectedRoute";
// import IdleTimerComponent from '../common/idleCheck/IdleTimerComponent';
import { Redirect } from "react-router";
import InternalAccessComponent from "../components/InternalAccessComponent/InternalAccessComponent";

class RouteComponent extends Component {
  /**
   *
   * @param {Object} props - This has all the component props present at the creation phase
   */
  constructor(props) {
    super(props);
    this.state = {
      pageName: this.props.pageData ? this.props.pageData.pageName : "",
    };
    this.allRoutesInfo = [];
  }

  check() {
    if (this.props.guardData && this.props.guardData.guard === true) {
      return (
        <React.Fragment>
          <ProtectedRoute
            exact
            path={ROUTES.APPOINTMENTDETAILS}
            component={CheckinAppointmentComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.DELIVERYDETAILS}
            component={CheckinDeliveryDetail}
          />
          <ProtectedRoute
            exact
            path={ROUTES.TERMSANDCONDITION}
            component={TermsAndConditionsComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.CONFIRMTRAILERDETAILS}
            component={ConfirmTrailerDetailCompoent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.SEALDETAIL}
            component={CheckOutSealComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.ACKNOWLEDGEMENT}
            component={CheckoutAcknowledgementComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.SIGNDATA}
            component={CheckOutSignComponent__Typed}
          />
          <ProtectedRoute
            exact
            path={ROUTES.INTERNALACCESS}
            component={InternalAccessComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.DROPTRAILERS}
            component={DropTrailerEnterDetails}
          />
          <ProtectedRoute
            exact
            path={ROUTES.DROPTRAILERRESULT}
            component={DropTrailerResultComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.DELIVERYANDRETURN}
            component={DeliveryAndReturnComponent}
          />
          <ProtectedRoute
            exact
            path={ROUTES.DELIVERYANDRETURNENTERDETAIL}
            component={DeliveryAndReturnEnterDetailComponent}
          />
          {/* <ProtectedRoute
            exact
            path={ROUTES.LANGUAGE}
            component={LanguageComponent}
          /> */}
        </React.Fragment>
      );
    }
  }

  defaultComponent() {
    if (!!getData("pageName")) {
      const pagename = getData("pageName").pageName;
      if (pagename === "checkin") {
        // return <Redirect to={ROUTES.CHECKINGATEPASS} />;
        return <Redirect to={ROUTES.LANGUAGE} />;
      } else if (pagename === "checkout") {
        // return <Redirect to={ROUTES.EXITGATE} />;
        return <Redirect to={ROUTES.LANGUAGE} />;
      } else {
        return <Redirect to={ROUTES.CONFIGURATION} />;
      }
    } else {
      return <Redirect to={ROUTES.CONFIGURATION} />;
    }
  }

  render() {
    return (
      <div className="app-wrapper">
        {/* <IdleTimerComponent /> */}
        <div className="appContainer">
          <Router>
            <div className="customContainer">
              <AlertModal />
              <Spinner name={spinnerName.LOGIN_SPINNER} />
              <Switch>
                {/* <Route exact path={ROUTES.LANDING} component={LandingComponent} /> */}
                {/* {this.loggedInComponent()} */}
                <LanguageConfigureRoute
                  exact
                  path={ROUTES.LANGUAGE}
                  component={LanguageComponent}
                />
                <PublicRoute
                  exact
                  path={ROUTES.LANDING}
                  component={LandingComponent}
                />
                <CheckIsConfigured
                  exact
                  path={ROUTES.CONFIGURATION}
                  component={ConfigureComponent}
                />

                <CheckOutConfigureRoute
                  exact
                  path={ROUTES.CHECKOUTGATEPASS}
                  component={CheckoutGatePassComponent}
                />
                {/* <CheckOutConfigureExitRoute
                  exact
                  path={ROUTES.CHECKOUTFLOWSELECTION}
                  component={CheckoutFlowSelection}
                /> */}
                <CheckOutConfigureExitRoute
                  exact
                  path={ROUTES.EXITGATE}
                  component={CheckoutExitComponent}
                />
                <CheckOutConfigureRoute
                  exact
                  path={ROUTES.EXITINTERMEDIATE}
                  component={ExitFlowIntermediate}
                />
                <CheckinConfigureRoute
                  exact
                  path={ROUTES.CHECKINGATEPASS}
                  component={CheckinGatePassComponent}
                />

                {this.check()}
                {this.defaultComponent()}
              </Switch>
            </div>
          </Router>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(RouteComponent);
