export class SpinnerService {
  /**
   * @description construction to initialize class variables
   * @returns {null} doesnot return anything
   */
  constructor() {
    this.spinnerCache = new Set();
  }

  /**
   * @description this function registers a spinner
   * @param {string} spinner spinner name
   * @returns {null} doesnot return anything
   */
  _register(spinner) {
    this.spinnerCache.add(spinner);
  }

  /**
   * @description this function deletes a spinner
   * @param {string} spinnerToRemove spinner name
   * @returns {null} doesnot return anything
   */
  _unregister(spinnerToRemove) {
    this.spinnerCache.forEach(spinner => {
      if (spinner === spinnerToRemove) {
        this.spinnerCache.delete(spinner);
      }
    });
  }

  // _unregisterGroup(spinnerGroup) {
  //   this.spinnerCache.forEach(spinner => {
  //     if (spinner.group === spinnerGroup) {
  //       this.spinnerCache.delete(spinner);
  //     }
  //   });
  // }

  /**
   * @description this function deletes all spinner
   * @returns {null} doesnot return anything
   */
  _unregisterAll() {
    this.spinnerCache.clear();
  }

  /**
   * @description this function shows a spinner changes flag to true
   * @param {string} spinnerName spinner name
   * @returns {null} doesnot return anything
   */
  show(spinnerName) {
    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        spinner.show = true;
      }
    });
  }

  /**
   * @description this function hides a spinner
   * @param {string} spinnerName spinner name
   * @returns {null} doesnot return anything
   */
  hide(spinnerName) {
    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        spinner.show = false;
      }
    });
  }

  // showGroup(spinnerGroup) {
  //   this.spinnerCache.forEach(spinner => {
  //     if (spinner.group === spinnerGroup) {
  //       spinner.show = true;
  //     }
  //   });
  // }

  // hideGroup(spinnerGroup) {
  //   this.spinnerCache.forEach(spinner => {
  //     if (spinner.group === spinnerGroup) {
  //       spinner.show = false;
  //     }
  //   });
  // }

  /**
   * @description this function shows all spinner
   * @returns {null} doesnot return anything
   */
  showAll() {
    this.spinnerCache.forEach(spinner => (spinner.show = true)); //eslint-disable-line no-return-assign
  }

  /**
   * @description this function hides all spinner
   * @returns {null} doesnot return anything
   */
  hideAll() {
    this.spinnerCache.forEach(spinner => (spinner.show = false)); //eslint-disable-line no-return-assign
  }

  /**
   * @description this function returns visibility of a spinner
   * @param {string} spinnerName name of spinner
   * @returns {boolean} visibility of spinner
   */
  isShowing(spinnerName) {
    let showing;
    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        showing = spinner.show;
      }
    });
    return showing;
  }
}

const spinnerService = new SpinnerService();
export { spinnerService };
