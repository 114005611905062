import React, { Component } from "react";
import { connect } from "react-redux";
import * as ROUTES from '../../constants/routes';
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import Header from "../../common/Header";
import { getData } from '../../containers/storageHandler';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";

class InternalAccessComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutName: "default",
      inputName: "input1",
      facilityId: getData('configureData').facilityId,
      laneNo: getData('configureData').laneNo,
      input: {},
      accessCode: ''
    };
  }

  handleChange(event) {
    let inputVal = event.target.value;
    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj,
        [event.target.name]: inputVal
      },
      () => {
        this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  back() {
    this.props.history.goBack();
  }

  async handleConfirm() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.accessCode) {
      const internalAccessObj = {
        org_id: this.state.facilityId,
        reference_id: this.state.accessCode,
        language: this.props.languageData
          ? this.props.languageData.language
          : 'en',
        lane_no: this.state.laneNo
      };
      const language = langType;
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      await CallApi('POST', CONFIG.internalAccess, {}, internalAccessObj, language, "returnInternalAccess", this);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.PleaseEnterAccessCode);
    }
  }

  returnInternalAccess = response => {
    // if (response.success) {
    AlertModal.showAlert(response.message, 'internalAccess', this.handleAction);
    // } else {}
  }

  handleAction = () => {
    this.backToLanguageScreen()
    AlertModal.hideAlert();
  }
  // ---------------------------------------------------------------------------------

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      accessCode: inputObj.input1
    });

  };

  onKeyPress = button => {

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.handleConfirm();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };

  backToLanguageScreen(){
    this.props.history.push(ROUTES.LANGUAGE);
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Header history={this.props.history} />
        <div className="body-container keyboard-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container white-box container-586">
                  <h4>{LANGCONSTANT[langType].STRINGS.PleaseEnterAccessCode}</h4>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        name='accessCode'
                        placeholder={LANGCONSTANT[langType].STRINGS.EnterAccessCode}
                        // value={this.state.accessCode}
                        onChange={event => this.handleChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="button"
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                        className="btn btn-disable"
                        onClick={() => this.back()}
                      />
                      <input
                        type="button"
                        value={LANGCONSTANT[langType].BUTTONS.Submit}
                        className="btn btn-submit"
                        onClick={() => this.handleConfirm()}
                      />
                    </div>
                  </form>
                  <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: [
                          // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                          "q w e r t y u i o p {bksp}",
                          "a s d f g h j k l {enter}",
                          "z x c v b n m",
                          "{space}"
                        ]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />

                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseClass={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(InternalAccessComponent);
