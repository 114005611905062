import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux';
import * as guardAction from '../../redux/actions/guardAction';
import { connect } from 'react-redux';
import { setData } from '../../containers/storageHandler';

class IdleTimerComponent extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.state = {}
        if(this.props.currentPage == "CheckOutSign"){
            this.state = {
                timeout: 30000,
                remaining: null,
                isIdle: false,
                lastActive: null,
                elapsed: null
            };
        }
        else{
            this.state = {
                timeout: 45000,
                remaining: null,
                isIdle: false,
                lastActive: null,
                elapsed: null
            };
        }
        
        // Bind event handlers and methods
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.reset = this._reset.bind(this);
        this.changeTimeout = this._changeTimeout.bind(this);
    }

    componentWillMount() { }

    _onActive() {
        this.setState({ isIdle: false });
    }

    _onIdle() {
        this.setState({ isIdle: true });
        const dataObj = {
            guard: false
        };
        setData('guard', false);
        this.props.guardAction.guardData(dataObj);
        this.reset();
    }

    _changeTimeout() {
        this.setState({
            timeout: this.refs.timeoutInput.state.value()
        });
    }

    _reset() {
        this.idleTimer.reset();
    }

    // _pause() {
    //     this.idleTimer.pause();
    // }

    // _resume() {
    //     this.idleTimer.resume();
    // }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref;
                    }}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    timeout={this.state.timeout}
                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    const data = state.data;
    return data;
}

function mapDispatchToProps(dispatch) {
    return {
        // actions: bindActionCreators(dataAction, dispatch)
        guardAction: bindActionCreators(guardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IdleTimerComponent);
