import * as cryptoUtils from '../utils/cryptoUtils'

// store data in localStorage
export const setData = (key, value) => {
  let userData = localStorage.getItem('userData');
  if(userData) {
    userData = cryptoUtils.decryptData(userData);
    userData = JSON.parse(userData);
    userData[key] = value;
  } else {
    userData = {[key]: value};
  }
  userData = JSON.stringify(userData);
  userData = cryptoUtils.encryptData(userData);
  localStorage.setItem('userData', userData);
}

// get data from localStorage
export const getData = (key) => {
  let userData = localStorage.getItem('userData');
  if(userData) {
    userData = JSON.parse(cryptoUtils.decryptData(userData));
    if(userData[key]){
      return userData[key];
    }
  }
}

// remove data from localStorage
export const removeData = (key) => {
  let userData = localStorage.getItem('userData');
  if(userData) {
    userData = JSON.parse(cryptoUtils.decryptData(userData));
    if(userData[key]) {
      delete userData[key];
      userData = cryptoUtils.encryptData(JSON.stringify(userData));
      localStorage.setItem('userData', userData);
    }
  }
}