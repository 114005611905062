import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/checkinAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Header from '../../common/Header';
import AlertModal from "../../common/modals/AlertModal";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class TermsAndConditionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
      dropTrailerData: false,
      checkin: true,
      pageNumber: 4,
      pageName: { pageName: LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.CheckIn },
      pageNameList: [
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.SelectAppointment,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.EnterDetails,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC
      ],
      dropTrailerList: [
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.Result
      ],
      totalPage: 4,
      dockdoor: '',
      shipment_flag: ''
    };
  }

  componentWillMount() {
    this.checkPreviousPage();
  }

  handleChange(event) {
    if (!this.state.check) {
      this.setState({
        check: true,
        confirm: false
      });
    } else {
      this.setState({
        check: false
      });
    }
  }

  back() {
    this.props.history.goBack();
  }

  handleConfirm() {
    // await this.checkPreviousPage();
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.check) {
      if (!this.state.dropTrailerData) {
        const index = this.props.location.state.index;
        const reqObj = {
          reference_id: this.props.deliveryData ? this.props.deliveryData.reference_id : '',
          org_id: this.props.masterData ? this.props.masterData.org_id : '',
          customer_po: this.props.masterData ? this.props.masterData.delivery[index][0].customer_po : '',
          delivery_id: this.props.masterData ? this.props.masterData.delivery[index][0].id : '',
          trip_id: this.props.masterData ? this.props.masterData.delivery[index][0].trip_id : '',
          visit_type: this.props.masterData ? this.props.masterData.delivery[index][0].visit_type : '',
          language: langType === 'ESP' ? 'es' : "en",
          lane_no: this.props.checkinData ? (this.props.checkinData.laneNo ? parseInt(this.props.checkinData.laneNo, 10) : '') : '',
          carrier_scac: this.props.masterData ? this.props.masterData.delivery[index][0].carrier_scac : '',
          carrier_name: this.props.masterData ? this.props.masterData.delivery[index][0].carrier_name : '',
          driver_name: this.props.deliveryData ? this.props.deliveryData.driverName : '',
          phone_no: this.props.deliveryData ? this.props.deliveryData.mobileNumber : '',
          trailer_length: this.props.deliveryData ? (this.props.deliveryData.trailerSize ? parseInt(this.props.deliveryData.trailerSize, 10) : '') : '',
          trailer_Type: this.props.deliveryData ? this.props.deliveryData.trailerType : '',
          trailer_number: this.props.deliveryData ? this.props.deliveryData.trailerNumber : '',
          customer_name: this.props.masterData ? this.props.masterData.delivery[index][0].customer_name : '',
          address: this.props.masterData ? this.props.masterData.delivery[index][0].address : '',
          did_query_return_trailer_number: this.props.location.state.didQueryReturnTrailerNumber
        };
        if (reqObj.reference_id !== '' && reqObj.visit_type !== '') {
          spinnerService.show(spinnerName.LOGIN_SPINNER);
          CallApi('POST', CONFIG.orderCheckIn, {}, reqObj, langType, "returnOrderCheckin", this);
        }
        else {
          AlertModal.showAlert(LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].STRINGS.EnterDetails);
        }
      }
      else {
        const dropTrailerDataObj = {
          carrier_scac: this.props.dropTrailerData ? this.props.dropTrailerData.carrier_scac : '',
          driver_name: this.props.dropTrailerData ? this.props.dropTrailerData.driver_name : '',
          lane_no: this.props.dropTrailerData ? this.props.dropTrailerData.lane_no : '',
          language: langType === 'ESP' ? 'es' : "en",
          liftGate: this.props.dropTrailerData ? this.props.dropTrailerData.liftGate : '',
          org_id: this.props.dropTrailerData ? this.props.dropTrailerData.org_id : '',
          phone_no: this.props.dropTrailerData ? this.props.dropTrailerData.phone_no : '',
          reference_id: '',
          trailer_length: this.props.dropTrailerData ? this.props.dropTrailerData.trailer_length : '',
          trailer_number: this.props.dropTrailerData ? this.props.dropTrailerData.trailer_number : '',
          trailer_type: this.props.dropTrailerData ? this.props.dropTrailerData.trailer_type : '',
          visit_type: "DE",
          did_query_return_trailer_number: this.props.location.state.didQueryReturnTrailerNumber
        };
        const flowCheck = (this.props.location.state && this.props.location.state.checkFlow) ?
          this.props.location.state.checkFlow : false;
        if (!flowCheck) {
          spinnerService.show(spinnerName.LOGIN_SPINNER);
          CallApi('POST', CONFIG.dropEmpty, {}, dropTrailerDataObj, langType, "returnDropEmpty", this);
        } else {
          // checkinAndDropEmptyObj flow
          const appointmentData = Object.keys(this.props.masterData).indexOf("delivery") ? this.props.masterData.delivery[0] : [];
          const index = this.props.location.state && this.props.location.state.index;
          const primaryobj = dropTrailerDataObj;
          primaryobj.visit_type = "DE";
          const checkinAndDropEmptyObj = {
            primary_details: primaryobj,
            sec_details: {
              reference_id: (this.props.location.state && this.props.location.state.reference_id) ?
                this.props.location.state.reference_id : '',
              org_id: this.props.dropTrailerData ? this.props.dropTrailerData.org_id : '',
              customer_po: appointmentData[index].customer_po,
              delivery_id: appointmentData[index].id,
              trip_id: appointmentData[index].trip_id,
              visit_type: "PL",
              language: langType === 'ESP' ? 'es' : "en",
              lane_no: this.props.dropTrailerData ? this.props.dropTrailerData.lane_no : '',
              carrier_scac: appointmentData[index].carrier_scac,
              driver_name: this.props.dropTrailerData ? this.props.dropTrailerData.driver_name : '',
              phone_no: this.props.dropTrailerData ? this.props.dropTrailerData.phone_no : '',
              trailer_length: appointmentData[index].trailer_details.trailer_length ? appointmentData[index].trailer_details.trailer_length : '',
              trailer_number: appointmentData[index].trailer_details.trailer_number ? appointmentData[index].trailer_details.trailer_number : '',
              trailer_type: appointmentData[index].trailer_details.trailer_type ? appointmentData[index].trailer_details.trailer_type : '',
              customer_name: appointmentData[index].customer_name,
              address: appointmentData[index].address,
              did_query_return_trailer_number: this.props.location.state.didQueryReturnTrailerNumber
            }
          };
          spinnerService.show(spinnerName.LOGIN_SPINNER);
          CallApi('POST', CONFIG.checkinAndDropEmpty, {}, checkinAndDropEmptyObj, langType, "returnCheckinAndDropEmpty", this);
        }
      }
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.CheckTerms);
    }
  }

  returnOrderCheckin = response => {
    if (response.result.checkin_status) {
      this.setState({
        confirm: true,
        dockdoor: response.result.dockdoor,
        shipment_flag: response.result.shipment_flag
      });
    } else {
      AlertModal.showAlert(response.result.shipment_flag);
    }
  };

  returnDropEmpty = response => {
    if (response.success === true) {
      if (response.result.checkin_status === true) {
        spinnerService.hide(spinnerName.LOGIN_SPINNER);
        this.props.history.push({
          pathname: ROUTES.DROPTRAILERRESULT,
          state: {
            detail: ROUTES.TERMSANDCONDITION,
            dockdoor: response.result.dockdoor,
            shipment_flag: response.result.shipment_flag
          }
        });
      } else {
        AlertModal.showAlert(response.result.shipment_flag);
      }
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  returnCheckinAndDropEmpty = response => {
    if (response.success === true) {
      if (response.result.checkin_status === true) {
        this.props.history.push({
          pathname: ROUTES.DROPTRAILERRESULT,
          state: {
            detail: ROUTES.TERMSANDCONDITION,
            dockdoor: response.result.dockdoor,
            shipment_flag: response.result.shipment_flag
          }
        });
      } else {
        AlertModal.showAlert(response.result.shipment_flag);
      }
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  async checkPreviousPage() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.detail === '/droptrailers') {
        await this.setState({
          dropTrailerData: true,
          pageName: LANGCONSTANT[langType].LABELS.DropTrailers,
          pageNameList: [
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC,
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.Result
          ],
          totalPage: 3,
          pageNumber: 2
        });
      }
    } else {
      this.setState({ checkin: true, pageName: LANGCONSTANT[langType].LABELS.CheckIn });
    }
  }

  backToHome() {
    this.props.history.push(ROUTES.LANGUAGE);
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    if (!this.state.confirm) {
      return (
        <div>
          <IdleTimerComponent />
          <Header history={this.props.history} />
          <div className="body-container">
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container terms-listing">
                    <div className="white-box container-890">
                      <h4>{LANGCONSTANT[langType].LABELS.TermsAndConditions}</h4>
                      <div className="row" style={{flexDirection: "column"}}>
                        {/* <div className="col-6"> */}
                        <div>
                          1. {LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          2. {LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          3. {LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          4. {LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          5. {LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          6. {LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          7. {LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          8. {LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          9. {LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          10. {LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          11. {LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}
                        </div>
                        {/* <div className="col-6"> */}
                        <div>
                          12. {LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}
                        </div>
                      </div>
                      <form>
                        <div className="form-group">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.check}
                              onChange={event => this.handleChange(event)}
                            />{' '}
                            <span>
                              {LANGCONSTANT[langType].STRINGS.TermsCondition}
                            </span>
                          </label>
                        </div>
                        <div className="text-right btn-wrapper ">
                          <input
                            type="button"
                            className="btn btn-disable"
                            onClick={() => this.back()}
                            value={LANGCONSTANT[langType].BUTTONS.Back}
                          />
                          <input
                            type="button"
                            className="btn btn-submit"
                            onClick={() => this.handleConfirm()}
                            value={LANGCONSTANT[langType].BUTTONS.Confirm}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="progress-bar-bottom">
              <PageProgressComponent
                pageName={(this.props.location.state && this.props.location.state.detail) === '/droptrailers' ?
                  (LANGCONSTANT[langType].LABELS.DropTrailers) :
                  (LANGCONSTANT[langType].LABELS.CheckIn)
                }
                pageNumber={this.state.pageNumber}
                totalPage={this.state.totalPage}
                pageNameList={(this.props.location.state && this.props.location.state.detail) === '/droptrailers' ?
                  ([
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.Result
                  ]) : ([
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.SelectAppointment,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.EnterDetails,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC
                  ])}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.confirm) {
      return (
        <div>
          <IdleTimerComponent/>
          <div className="body-container">
            <section>
              <div className="checkout-process-tabber">
                <PageProgressComponent
                  pageName={LANGCONSTANT[langType].LABELS.CheckIn}
                  pageNumber={this.state.pageNumber}
                  totalPage={this.state.totalPage}
                  pageNameList={[
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.SelectAppointment,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.EnterDetails,
                    LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC
                  ]}
                />
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container terms-listing">
                    <div className="checkout-process-container container-890 white-box gate-allotted text-center">
                      <div className="tick">
                        <img
                          src={require('../../assets/images/tick.png')}
                          alt=""
                          width="88px;"
                        />
                      </div>
                      {/* <h4>{LANGCONSTANT[langType].LABELS.SuccessfullyChecked}</h4> */}
                      <h4>{this.state.dockdoor}</h4>
                      <h6>{this.state.shipment_flag}</h6>
                      {/* <p>{this.state.shipment_flag}</p> */}
                      <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.backToHome()}
                        value={LANGCONSTANT[langType].BUTTONS.BackToHome}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}
TermsAndConditionsComponent.propTypes = {
  actions: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionsComponent);
