import React, { Component } from "react";
import { connect } from "react-redux";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import Header from "../../common/Header";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class CheckOutSealComponent extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.state.selectedTrailerDetail)
    this.state = {
      sealNumber: "",
      pageNumber: 4,
      pageNameList: [
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
      ],
      internalAccessModalIsOpen: false,
      layoutName: "default",
      inputName: "input1",
      input: {}
    };
    this.closeInternalAccessModal = this.closeInternalAccessModal.bind(this);
    this.openInternalAccessModal = this.openInternalAccessModal.bind(this);
    this.afterInternalAccessOpenModal = this.afterInternalAccessOpenModal.bind(this);
  }

  openInternalAccessModal() {
    this.setState({ internalAccessModalIsOpen: true });
  }
  closeInternalAccessModal() {
    this.setState({
      internalAccessModalIsOpen: false
    });
  }

  async handleSubmit() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.sealNumber) {
      this.props.location.state.selectedTrailerDetail["seal_no"]= this.state.sealNumber;
      this.props.history.push({
        pathname: ROUTES.ACKNOWLEDGEMENT,
        state: {
          selectedTrailerDetail: this.props.location.state.selectedTrailerDetail
        }
      });
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.CheckSeal);
    }
    this.closeInternalAccessModal();
  }
  afterInternalAccessOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#000000";
  }

  handleChange(event) {
    let inputVal = event.target.value;

    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj
      },
      () => {
        this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  back() {
    this.props.history.goBack();
  }

  handleConfirm() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.sealNumber) {
      //this.openInternalAccessModal();
      this.handleSubmit();
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.SealNumber);
    }
  }

  // ---------------------------------------------------------------------------------

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      sealNumber: inputObj.input1
    });

  };

  onKeyPress = button => {

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.handleSubmit(); //this.handleConfirm();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent />
        <Header history={this.props.history} />
        <div className="body-container keyboard-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container white-box container-586">
                  <h4>{LANGCONSTANT[langType].STRINGS.SealNumberManually}</h4>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        placeholder={LANGCONSTANT[langType].STRINGS.SealNumber}
                        value={this.state.sealNumber}
                        onChange={event => this.handleChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="button"
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                        className="btn btn-disable"
                        onClick={() => this.back()}
                      />
                      <input
                        type="button"
                        value={LANGCONSTANT[langType].BUTTONS.Confirm}
                        className="btn btn-submit"
                        onClick={() => this.handleSubmit()} //this.handleConfirm()}
                      />
                    </div>
                  </form>
                  <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: [
                          // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                          "q w e r t y u i o p {bksp}",
                          "a s d f g h j k l {enter}",
                          "z x c v b n m",
                          "{space}"
                        ]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />

                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseClass={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="progress-bar-bottom">
            <PageProgressComponent
              pageName={LANGCONSTANT[langType].LABELS.CheckOut}
              pageNumber={this.state.pageNumber}
              totalPage={5}
              pageNameList={
                [
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Acknowledgement,
                  LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
                ]
              }
            />
          </div>
        </div>
        <div>
          {/* <Modal
            isOpen={this.state.internalAccessModalIsOpen}
            onAfterOpen={this.afterInternalAccessOpenModal}
            onRequestClose={this.closeInternalAccessModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <p ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.ConfirmSeal}</p>
                <div className="form-group" style={{ color: "#797979" }}>
                  {this.state.sealNumber}
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-disable"
                    onClick={this.closeInternalAccessModal}
                    style={{ backgroundColor: "#797979" }}>
                    {LANGCONSTANT[langType].BUTTONS.Cancel}
                  </button>
                  <button className="btn btn-submit" onClick={() => this.handleSubmit()}>
                    {LANGCONSTANT[langType].BUTTONS.Submit}
                  </button>
                </div>
              </div>
            </div>
          </Modal> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckOutSealComponent);
