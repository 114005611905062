import React, { Component } from 'react';

class PageProgressComonent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.pageNameList,
      pageNumber: this.props.pageNumber,
      pageName: this.props.pageName
    };
  }

  render() {
    return (
      <div>
        <section>
          <div className="checkout-process-tabber">
            <ul className="d-flex container-686">
              <li>
                <h4>{this.props.pageName}</h4>
              </li>
              {this.props.pageNameList.map((num, index) => {
                return (
                  <li
                    key={index}
                    className={
                      index === this.state.pageNumber - 1 ? 'active' : ''
                    }
                    disabled={index === 0 ? false : true}
                  >
                    <span className="count">{index + 1}</span>
                    <small>{num}</small>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

export default PageProgressComonent;
