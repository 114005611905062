export const CHECKOUTDATA = 'checkoutData';
export const CHECKINDATA = 'checkinData';
export const MASTERDATA = 'masterData';
export const SHIPMENTDATA = 'shipmentData';
export const DELIVERYDATA = 'deliveryData';
export const DROPTRAILERDATA = 'dropTrailerData';
export const DELIVERYRETURNDATA = 'deliveryReturnData';
export const CHECKOUTSHIPMENTDATA = 'checkoutShipmentData';
export const LANGUAGEDATA = 'languageData';
export const PAGEDATA = 'pageData';
export const GUARDDATA = 'guardData';
