import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import * as ROUTES from '../constants/routes';
import {
  isAuthenticated,
  isCheckOutConfigured,
  isCheckOutExitConfigured,
  isCheckOutExitFlowConfigured,
  isCheckInConfigured,
  checkIsConfigured,
  checkLandingPage
} from '../containers/auth';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const CheckinConfigureRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isCheckInConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const CheckOutConfigureRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isCheckOutConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const CheckOutConfigureExitRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isCheckOutExitFlowConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const CheckOutExitRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isCheckOutExitConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const CheckIsConfigured = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && checkIsConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
);

export const LanguageConfigureRoute = ({ component: Component, ...rest})=>(
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && !checkIsConfigured() ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          />
        )
    }
  />
)

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={checkLandingPage()} />
        )
    }
  />
);
