import { getData } from "./storageHandler";

export const isAuthenticated = () => {
  if (!getData('isLogin')) {
    return false;
  } else {
    return true;
  }
};

export const isCheckInConfigured = () => {
  if (
    getData('configureData') &&
    !!(getData('pageName').pageName === 'checkin')
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCheckOutConfigured = () => {
  if (
    getData('configureData') &&
    !!(getData('pageName').pageName === 'checkout') &&
    !!(getData('isExitFlow') === 1)) {
    return true;
  } else {
    return false;
  }
};

export const isCheckOutExitConfigured = () => {
  if (
    getData('configureData') &&
    !!(getData('pageName').pageName === 'checkout') &&
    !!(getData('isExitFlow') === 2)) {
    return true;
  } else {
    return false;
  }
};

export const isCheckOutExitFlowConfigured = () => {
  if (
    getData('configureData') &&
    !!(getData('pageName').pageName === 'checkout')) {
    return true;
  } else {
    return false;
  }
};

export const checkIsConfigured = () => {
  if (!!getData('configureData')) {
    return false;
  } else {
    return true;
  }
};

export const checkLandingPage = () => {
  if (!!getData('pageName')) {
    const pagename = getData('pageName').pageName;
    if (pagename === 'checkin') {
      return ("/checkingatepass")
    } else if (pagename === 'checkout') {
      // this.props.history.push(ROUTES.CHECKOUTGATEPASS);
      return ("/flowselection");
    } else {
      return ("/configuration");
    }
  } else {
    return ("/configuration");
  }
}
