import React, { Component } from "react";
import { CONFIG } from "../../configuration/config";
import { CallApi } from "../../services/service";
import * as ROUTES from "../../constants/routes";
import StarRatingComponent from "react-star-rating-component";
import PageProgressComponent from "../../common/PageProgressComponent";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { spinnerName } from "../../constants/data";
import { spinnerService } from "../../services/spinner.service";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import Header from "../../common/Header";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import AlertModal from "../../common/modals/AlertModal";
import { getData } from "../../containers/storageHandler";
import Modal from "react-modal";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../index.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class CheckOutSignComponent__Typed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signData: "",
      modalIsOpen: false,
      feedbackModalIsOpen: false,
      rating: 0,
      index: 0,
      feedBackText: "",
      pageNumber: 6,
      modalMessage: "",
      checkoutObj: {},
      configurationData: getData("configureData"),
      selectedTrailerDetail: "",
      selectedTrailerDetail: this.props.location.state.selectedTrailerDetail,
      userSignatureTextFirst: "Sample",
      userSignatureTextLast: "",
      userSignatureTextFinal: "",
      userSignatureFonts: [
        "Dancing Script",
        "Sacramento",
        "Cedarville Cursive",
      ],
      userSignatureFontNum: 0,
      userSignatureConfirmed: false,
      inputName: "userSignatureTextFirst",
      imgsrc: "",
      input: {},
      showSignatureStyles: false,
    };
  }

  componentDidMount() {
    this.setState({
      index: this.props.location.state ? this.props.location.state.index : 0,
    });
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  openFeedbackModal() {
    this.setState({ feedbackModalIsOpen: true });
  }

  closeFeedbackModal() {
    this.setState({ feedbackModalIsOpen: false });
  }

  submitFeedback() {
    this.setState({ feedbackModalIsOpen: false });
    const feedBackObj = {
      profile_id: this.state.configurationData
        ? this.state.configurationData.facilityId
        : "",
      comments: this.state.feedBackText,
      ratings: this.state.rating,
    };
    if (feedBackObj.profile_id !== "") {
      const langType = LanguageUtils.getLangType(this.props);
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi(
        "POST",
        CONFIG.feedBack,
        {},
        feedBackObj,
        langType,
        "returnFeedback",
        this
      );
    }
  }

  returnFeedback = (response) => {
    if (response.success === true) {
      this.props.history.push(ROUTES.LANGUAGE);
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  cancel() {
    this.closeModal();
    this.props.history.push(ROUTES.LANGUAGE);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  back() {
    this.props.history.goBack();
  }
  isValidSignature = async () => {
    if (
      this.state.userSignatureTextFirst == "" ||
      this.state.userSignatureTextLast == ""
    ) {
      return false;
    }
    let firstExtraSpacesRemoved = this.state.userSignatureTextFirst
      .replace(/\s+/g, " ")
      .trim();
    let lastExtraSpacesRemoved = this.state.userSignatureTextLast
      .replace(/\s+/g, " ")
      .trim();
    let firstNoSpaces = firstExtraSpacesRemoved.replaceAll(" ", "");
    let lastNoSpaces = lastExtraSpacesRemoved.replaceAll(" ", "");
    if (firstNoSpaces.length < 1 || lastNoSpaces.length < 2) {
      return false;
    }
    this.setState({
      userSignatureTextFinal:
        this.state.userSignatureTextFirst +
        " " +
        this.state.userSignatureTextLast,
    });
    return true;
  };
  async handlePrint() {
    const langType = LanguageUtils.getLangType(this.props);
    if (!this.state.userSignatureConfirmed) {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.MustAgreeToSignature);
      return;
    }
    const validSignature = await this.isValidSignature();
    if (!validSignature) {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      AlertModal.showAlert(
        LANGCONSTANT[langType].STRINGS.EnterDetailsSignature
      );
      return;
    }
    const res = this.handleGenerateSignature();
    const signatureBase64 = res.split(",");
    const fullDriverName =
      this.state.input.userSignatureTextFirst +
      " " +
      this.state.input.userSignatureTextLast;
    this.setState({ signData: signatureBase64[1] });
    const checkoutObj = {
      reference_id: this.state.selectedTrailerDetail.reference_id, //this.props.guardData ? this.props.guardData.trailerNumber : "",
      org_id: this.state.selectedTrailerDetail.org_id, //this.props.checkoutData ? this.props.checkoutData.facilityId : "",
      app_no: this.state.selectedTrailerDetail.app_no, //this.props.deliveryData ? (this.props.deliveryData.delivery[index][0].trip_id || this.props.deliveryData.delivery[index][0].id || "") : "",
      seal_no: this.state.selectedTrailerDetail.seal_no, //this.props.location.state.sealNumber ? this.props.location.state.sealNumber : "",
      language: langType === "ESP" ? "es" : "en",
      lane_no: this.state.selectedTrailerDetail.lane_no, //this.props.checkoutData ? this.props.checkoutData.laneNo : "",
      carrier_name: this.state.selectedTrailerDetail.carrier_name, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].carrier_name : "",
      carrier_scac: this.state.selectedTrailerDetail.carrier_scac,
      driver_name: fullDriverName, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.driver_name : "",
      phone_no: this.state.selectedTrailerDetail.phone_no, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.driver_phone : "",
      base64: this.state.signData,
      trailer_number: this.state.selectedTrailerDetail.trailer_number, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.trailer_number : "",
      visit_type: this.state.selectedTrailerDetail.visit_type, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].visit_type : ""
    };
    this.setState({ checkoutObj });

    if (
      checkoutObj.org_id !== "" &&
      this.state.input.userSignatureTextFirst +
        this.state.input.userSignatureTextLast !==
        ""
    ) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      if (checkoutObj.lane_no) {
        CallApi(
          "POST",
          CONFIG.orderCheckout,
          {},
          checkoutObj,
          langType,
          "orderCheckoutCallback",
          this
        );
      } else {
        spinnerService.hide(spinnerName.LOGIN_SPINNER);
        AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.laneNumMissing);
      }
    } else {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      AlertModal.showAlert(
        LANGCONSTANT[langType].STRINGS.EnterDetailsSignature
      );
    }
  }

  // exitGateCallback = (response) => {
  //   const language = LanguageUtils.getLangType(this.props);
  //   if (response.success == true) {
  //     spinnerService.show(spinnerName.LOGIN_SPINNER);
  //     CallApi("POST", CONFIG.orderCheckout, {}, this.state.checkoutObj, language, "returnOrderCheckout", this);
  //   }
  // };

  orderCheckoutCallback = (response) => {
    const language = LanguageUtils.getLangType(this.props);
    let index = getData("laneIndex") || 1;
    const exitObj = {
      language: language === "ESP" ? "es" : "en",
      reference_id: "",
      org_id: this.state.configurationData
        ? this.state.configurationData.facilityId
        : "",
      lane_no: index,
    };
    if (response.success == true && response.result.status === true) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi(
        "POST",
        CONFIG.exitGate,
        {},
        exitObj,
        language,
        "returnInternalAccess",
        this
      );
    } else {
      AlertModal.showAlert(response.result.message);
    }
  };

  // returnOrderCheckout = response => {
  //   if (response.success === true) {
  //     if (response.result.status === true) {
  //       this.openFeedbackModal();
  //     } else {
  //       AlertModal.showAlert(response.result.message);
  //     }
  //   }
  // };

  // ----------------------------------------------------------------Onscreen React Keyboard
  onChangeAll = (inputObj) => {
    this.setState({
      input: inputObj,
      userSignatureTextFirst:
        (inputObj.userSignatureTextFirst == undefined ||
          inputObj.userSignatureTextFirst == "") &&
        (inputObj.userSignatureTextLast == undefined ||
          inputObj.userSignatureTextLast == "")
          ? "Sample"
          : inputObj.userSignatureTextFirst,

      userSignatureTextLast:
        inputObj.userSignatureTextLast == undefined ||
        inputObj.userSignatureTextLast == ""
          ? ""
          : inputObj.userSignatureTextLast,
      userSignatureConfirmed: false,
    });
  };
  onKeyPress = (button) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.handlePrint();
  };

  setActiveInput = (inputName) => {
    this.setState(
      {
        inputName: inputName,
      },
      () => {}
    );
  };
  //------------------------------------------------------------------------
  returnInternalAccess = (response) => {
    //Disabled survey and just take user back to language selection page as per request of KC and Luis
    /*const language = LanguageUtils.getLangType(this.props);
    if (response.success === true) {
      if (response.result.status === "TRUE") {
        this.openFeedbackModal();
      } else {
        AlertModal.showAlert(response.result.message);
      }
    }*/
    this.props.history.push(ROUTES.LANGUAGE);
  };

  measureText = (text, font) => {
    const span = document.createElement("span");
    span.appendChild(document.createTextNode(text));
    Object.assign(span.style, {
      font: font,
      margin: "0",
      padding: "0",
      border: "0",
      whiteSpace: "nowrap",
    });
    document.body.appendChild(span);
    const { width, height } = span.getBoundingClientRect();
    span.remove();
    return { width, height };
  };

  resizeText = (ctx) => {
    var minFontSize = 5;
    let maxWidth = 400;
    let maxHeight = 200;
    let fontSize = 150;
    ctx.font =
      fontSize +
      "px" +
      " " +
      this.state.userSignatureFonts[this.state.userSignatureFontNum];
    let textDimensions = this.measureText(
      this.state.userSignatureTextFinal,
      ctx.font
    );
    if (textDimensions.width > maxWidth || textDimensions.height > maxHeight) {
      var newfontSize = fontSize;
      var decrement = 1;
      let newTextDimensions;
      while (
        textDimensions.width > maxWidth ||
        textDimensions.height > maxHeight
      ) {
        newfontSize -= decrement;
        if (newfontSize < minFontSize) {
          return minFontSize;
        }
        ctx.font =
          newfontSize +
          "px " +
          this.state.userSignatureFonts[this.state.userSignatureFontNum];
        newTextDimensions = this.measureText(
          this.state.userSignatureTextFinal,
          ctx.font
        );
        if (
          newTextDimensions.width > maxWidth ||
          newTextDimensions.height > maxHeight
        ) {
          textDimensions = newTextDimensions;
        } else {
          return newfontSize;
        }
      }
      return newfontSize;
    } else {
      return fontSize;
    }
  };

  handleGenerateSignature = () => {
    let cvs = document.createElement("canvas");
    let ctx = cvs.getContext("2d");
    let properFontSize = this.resizeText(ctx);
    cvs.setAttribute("width", "400px");
    cvs.setAttribute("height", "200px");

    ctx.clearRect(0, 0, cvs.width, cvs.height);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font =
      properFontSize +
      "px " +
      this.state.userSignatureFonts[this.state.userSignatureFontNum];
    let x = cvs.width / 2;
    let y = cvs.height / 2;
    ctx.fillText(this.state.userSignatureTextFinal, x, y);

    let dataUrl = cvs.toDataURL("image/png");
    let newImg = document.createElement("img");
    newImg.setAttribute("src", dataUrl);
    newImg.setAttribute("id", "newSigIMG");
    this.setState({ imgsrc: dataUrl });
    return dataUrl;
  };

  handleConfirmSignature = () => {
    this.setState({
      userSignatureConfirmed: !this.state.userSignatureConfirmed,
    });
  };

  render() {
    const { rating } = this.state;
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent currentPage={"CheckOutSign"} />
        <Header history={this.props.history} />
        <div>
          <div className="body-container">
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div
                    className="checkout-process-container container-890 white-box"
                    style={{ paddingTop: "12px" }}
                  >
                    <h5 style={{ marginBottom: "1px" }}>
                      1. {LANGCONSTANT[langType].STRINGS.SignatureFirstLast}:
                    </h5>
                    <div
                      style={{
                        marginBottom: "2px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <input
                            type="text"
                            onFocus={() =>
                              this.setActiveInput("userSignatureTextFirst")
                            }
                            value={
                              this.state.userSignatureTextFirst == "Sample"
                                ? ""
                                : this.state.userSignatureTextFirst
                            }
                            placeholder={
                              LANGCONSTANT[langType].LABELS.FirstName
                            }
                          ></input>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex" }}>
                          <input
                            type="text"
                            onFocus={() =>
                              this.setActiveInput("userSignatureTextLast")
                            }
                            value={
                              this.state.userSignatureTextLast == "Signature"
                                ? ""
                                : this.state.userSignatureTextLast
                            }
                            placeholder={LANGCONSTANT[langType].LABELS.LastName}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 style={{ marginBottom: "1px" }}>
                        2. {LANGCONSTANT[langType].STRINGS.SelectSignatureStyle}
                        :
                      </h5>

                      {this.state.userSignatureFonts.map((font, i) => (
                        <h1
                          style={{
                            fontFamily: font,
                            color: "black",
                            height: 45,
                            marginBottom: -2,
                            display: "flex",
                            justifyContent: "center",
                            border:
                              this.state.userSignatureFontNum === i
                                ? "2px dashed #1C6EA4"
                                : "",
                            fontSize: "2.4rem",
                          }}
                          onClick={() =>
                            this.setState({
                              userSignatureFontNum: i,
                              userSignatureConfirmed: false,
                            })
                          }
                        >
                          {this.state.userSignatureTextFirst +
                            " " +
                            this.state.userSignatureTextLast}
                        </h1>
                      ))}
                    </div>
                    <div>
                      <h5 style={{ marginBottom: "1px" }}>
                        3. {LANGCONSTANT[langType].STRINGS.ConfirmSignature}:
                      </h5>
                      <div style={{ display: "flex" }}>
                        <h1
                          style={{
                            fontFamily:
                              this.state.userSignatureFonts[
                                this.state.userSignatureFontNum
                              ],
                            color: "black",
                            height: 60,
                            marginBottom: 5,
                            display: "flex",
                            justifyContent: "center",
                            width: "400px",
                          }}
                        >
                          {this.state.userSignatureTextFirst}{" "}
                          {this.state.userSignatureTextLast}
                        </h1>
                        <div style={{ marginLeft: "auto", marginTop: "15px" }}>
                          <input
                            type="checkbox"
                            id="userSignatureConfirmedInput"
                            checked={this.state.userSignatureConfirmed}
                            onChange={() => this.handleConfirmSignature()}
                            style={{ width: "20px", height: "20px" }}
                          />{" "}
                          <label
                            for="userSignatureConfirmedInput"
                            style={{ fontSize: "150%" }}
                          >
                            {LANGCONSTANT[langType].STRINGS.AgreeToSignature}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <a
                        href="javaScript:void(0)"
                        onClick={() => this.openModal()}
                        data-target="#myModal"
                      >
                        <u>
                          {LANGCONSTANT[langType].LABELS.TermsAndConditions}
                        </u>
                      </a>
                      <span className="seal-number">
                        {LANGCONSTANT[langType].LABELS.SealNumber} :
                        {this.state.selectedTrailerDetail.seal_no
                          ? this.state.selectedTrailerDetail.seal_no
                          : ""}
                      </span>
                    </div>
                    <div className="btn-wrapper text-right">
                      <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.back()}
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                      />
                      <input
                        type="button"
                        className="btn btn-submit"
                        onClick={() => this.handlePrint()}
                        value={LANGCONSTANT[langType].BUTTONS.Submit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="progress-bar-bottom">
              <PageProgressComponent
                pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                pageNumber={this.state.pageNumber}
                totalPage={6}
                pageNameList={[
                  LANGCONSTANT[langType].LABELS.ExitGate,
                  LANGCONSTANT[langType].LABELS.ScanGatePass,
                  LANGCONSTANT[langType].LABELS.ConfirmDetails,
                  LANGCONSTANT[langType].LABELS.ScanSeal,
                  LANGCONSTANT[langType].LABELS.Acknowledgement,
                  LANGCONSTANT[langType].LABELS.Sign,
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal">
              <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                {LANGCONSTANT[langType].LABELS.TermsAndConditions}
              </h2>
              <ol>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}
                </li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}
                </li>
              </ol>
              <center>
                <button onClick={() => this.closeModal()}>
                  {LANGCONSTANT[langType].BUTTONS.Ok}
                </button>
              </center>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.feedbackModalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeFeedbackModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal feedback-modal text-center">
              <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                {LANGCONSTANT[langType].STRINGS.CheckoutComplete}
              </h2>
              <p className="feedback-msg">
                {LANGCONSTANT[langType].STRINGS.FeedBackMsg}
              </p>
              <p>
                <b>{LANGCONSTANT[langType].STRINGS.RateExperience}</b>
              </p>
              <div className="rating-stars">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  emptyStarColor={"#ffffff"}
                  value={rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div className="btn-alignment">
                <button onClick={() => this.cancel()}>
                  {LANGCONSTANT[langType].BUTTONS.Cancel}
                </button>
                <button onClick={() => this.submitFeedback()}>
                  {LANGCONSTANT[langType].BUTTONS.Submit}
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="keyboard d-flex">
          <Keyboard
            ref={(r) => (this.keyboardRef = r)}
            inputName={this.state.inputName}
            syncInstanceInputs={true}
            display={{
              "{bksp}": "backspace",
              "{enter}": " enter",
              "{space}": "space",
            }}
            layout={{
              default: [
                // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                "q w e r t y u i o p {bksp}",
                "a s d f g h j k l {enter}",
                "z x c v b n m",
                "{space}",
              ],
            }}
            onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

CheckOutSignComponent__Typed.propTypes = {
  dispatch: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckOutSignComponent__Typed);
