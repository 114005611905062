import React, { Component } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import * as LANGCONSTANT from '../../constants/language';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export default class AlertModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertModalIsOpen: false,
      modalMessage: '',
      returnMethod: null,
      identity: null
    };
    this.afterAlertOpenModal = this.afterAlertOpenModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
    AlertModal._singletonRef = this;
  }

  static showAlert(message, info = null, method = null) {
    AlertModal._singletonRef.setState({
      returnMethod: method,
      identity: info
    });
    AlertModal._singletonRef.openAlertModal(message);
  }

  openAlertModal(message) {
    this.setState({ alertModalIsOpen: true, modalMessage: message });
  }

  static hideAlert = () => {
    AlertModal._singletonRef.setState({
      alertModalIsOpen: false
    });
  }

  closeAlertModal() {
    const { identity, returnMethod, obj } = this.state;
    if (identity === 'internalAccess') {
      returnMethod();
    } else {
      this.setState({ alertModalIsOpen: false });
    }
  }

  afterAlertOpenModal() {
    this.subtitle.style.color = '#6495ed';
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Modal
          isOpen={this.state.alertModalIsOpen}
          onAfterOpen={this.afterAlertOpenModal}
          onRequestClose={this.closeAlertModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal confirmation-modal-dialog">
            <div className="delivery-access-body">
              <p ref={subtitle => (this.subtitle = subtitle)} />
              <div className="form-group" style={{ textAlign: 'center' }}>
                {this.state.modalMessage}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-submit"
                  onClick={() => this.closeAlertModal()}
                >
                  {LANGCONSTANT[langType].BUTTONS.Ok}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}