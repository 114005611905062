import React, { Component } from 'react';
import { Preloader } from 'react-materialize';

import { spinnerService } from '../../services/spinner.service';

export class SpinnerComponent extends Component {
  /**
   * @description constructor to initialize members
   * @param {string} props props received
   * @param {context} context context received
   * @returns {null} does not return anything
   */
  constructor(props, context) {
    super(props, context);

    const { show, name } = this.props;
    if (!name) {
      throw new Error('Spinner components must have a name prop.');
    }

    // if (!this.props.loadingImage && !this.props.children) {
    //   throw new Error(
    //     "Spinner components must have either a loadingImage prop or children to display."
    //   );
    // }

    this.state = {
      show: this.props.hasOwnProperty('show') ? show : false //eslint-disable-line
    };
    this.spinnerService = spinnerService;
    this.spinnerService._register(this);
  }

  /**
   * @description executes before component unmounts
   * @returns {null} does not return anything
   */
  componentWillUnmount() {
    this.spinnerService._unregister(this);
  }

  /**
   * @description getter for name
   * @returns {string} spinner name
   */
  get name() {
    const { name } = this.props;
    return name;
  }

  /**
   * @description getter for visibility
   * @returns {boolean} spinner visibility
   */
  get show() {
    const { show } = this.state;
    return show;
  }

  /**
   * @description setter for visibility
   * @param {boolean} show visibilty
   * @returns {null} only sets state returning null
   */
  set show(show) {
    this.setState({ show });
  }

  /**
   * @description renderr component
   * @returns {null} returns null
   */
  render() {
    // const divStyle = { display: "inline-block" };
    const { show } = this.state;
    if (show) {
      //   const { loadingImage } = this.props;
      return (
        <div className="loaderContainer">
          <Preloader />
          {/* {loadingImage && <img src={loadingImage} />}
          {this.props.children} */}
        </div>
      );
    }
    return <React.Fragment />;
  }
}
