import React, { Component } from "react";
import { CONFIG } from "../../configuration/config";
import { CallApi } from "../../services/service";
import * as ROUTES from "../../constants/routes";
import StarRatingComponent from "react-star-rating-component";
import PageProgressComponent from "../../common/PageProgressComponent";
import SignatureCanvas from "react-signature-canvas";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { spinnerName } from "../../constants/data";
import { spinnerService } from "../../services/spinner.service";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import Header from "../../common/Header";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import AlertModal from "../../common/modals/AlertModal";
import { getData } from "../../containers/storageHandler";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class CheckOutSignComponent__Written extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signData: "",
      modalIsOpen: false,
      feedbackModalIsOpen: false,
      rating: 0,
      index: 0,
      feedBackText: "",
      pageNumber: 6,
      modalMessage: "",
      checkoutObj: {},
      configurationData: getData("configureData"),
      selectedTrailerDetail: this.props.location.state.selectedTrailerDetail,
    };
  }

  componentDidMount() {
    this.setState({
      index: this.props.location.state ? this.props.location.state.index : 0,
    });
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  openFeedbackModal() {
    this.setState({ feedbackModalIsOpen: true });
  }

  closeFeedbackModal() {
    this.setState({ feedbackModalIsOpen: false });
  }

  submitFeedback() {
    this.setState({ feedbackModalIsOpen: false });
    const feedBackObj = {
      profile_id: this.state.configurationData
        ? this.state.configurationData.facilityId
        : "",
      comments: this.state.feedBackText,
      ratings: this.state.rating,
    };
    if (feedBackObj.profile_id !== "") {
      const langType = LanguageUtils.getLangType(this.props);
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi(
        "POST",
        CONFIG.feedBack,
        {},
        feedBackObj,
        langType,
        "returnFeedback",
        this
      );
    }
  }

  returnFeedback = (response) => {
    if (response.success === true) {
      this.props.history.push(ROUTES.LANGUAGE);
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  cancel() {
    this.closeModal();
    this.props.history.push(ROUTES.LANGUAGE);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  clear() {
    this.signPad.clear();
    this.setState({
      signData: "",
    });
  }

  back() {
    this.props.history.goBack();
  }

  async handlePrint() {
    const langType = LanguageUtils.getLangType(this.props);
    const res = this.signPad.getCanvas().toDataURL("image/png");
    var signPadbase64 = res.split(",");
    await this.setState({ signData: signPadbase64[1] });
    const checkoutObj = {
      reference_id: this.state.selectedTrailerDetail.reference_id, //this.props.guardData ? this.props.guardData.trailerNumber : "",
      org_id: this.state.selectedTrailerDetail.org_id, //this.props.checkoutData ? this.props.checkoutData.facilityId : "",
      app_no: this.state.selectedTrailerDetail.app_no, //this.props.deliveryData ? (this.props.deliveryData.delivery[index][0].trip_id || this.props.deliveryData.delivery[index][0].id || "") : "",
      seal_no: this.state.selectedTrailerDetail.seal_no, //this.props.location.state.sealNumber ? this.props.location.state.sealNumber : "",
      language: langType === "ESP" ? "es" : "en",
      lane_no: this.state.selectedTrailerDetail.lane_no, //this.props.checkoutData ? this.props.checkoutData.laneNo : "",
      carrier_name: this.state.selectedTrailerDetail.carrier_name, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].carrier_name : "",
      carrier_scac: this.state.selectedTrailerDetail.carrier_scac,
      driver_name: this.state.selectedTrailerDetail.driver_name, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.driver_name : "",
      phone_no: this.state.selectedTrailerDetail.phone_no, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.driver_phone : "",
      base64: this.state.signData,
      trailer_number: this.state.selectedTrailerDetail.trailer_number, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].trailer_details.trailer_number : "",
      visit_type: this.state.selectedTrailerDetail.visit_type, //this.props.deliveryData ? this.props.deliveryData.delivery[index][0].visit_type : ""
    };
    this.setState({ checkoutObj });
    if (!this.signPad.isEmpty() && checkoutObj.org_id !== "") {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      if (checkoutObj.org_id && checkoutObj.lane_no) {
        CallApi(
          "POST",
          CONFIG.orderCheckout,
          {},
          checkoutObj,
          langType,
          "orderCheckoutCallback",
          this
        );
      }
    } else {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  // exitGateCallback = (response) => {
  //   const language = LanguageUtils.getLangType(this.props);
  //   if (response.success == true) {
  //     spinnerService.show(spinnerName.LOGIN_SPINNER);
  //     CallApi("POST", CONFIG.orderCheckout, {}, this.state.checkoutObj, language, "returnOrderCheckout", this);
  //   }
  // };

  orderCheckoutCallback = (response) => {
    const language = LanguageUtils.getLangType(this.props);
    let index = getData("laneIndex") || 1;
    const exitObj = {
      language: language === "ESP" ? "es" : "en",
      reference_id: "",
      org_id: this.state.configurationData
        ? this.state.configurationData.facilityId
        : "",
      lane_no: index,
    };
    if (response.success == true && response.result.status === true) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi(
        "POST",
        CONFIG.exitGate,
        {},
        exitObj,
        language,
        "returnInternalAccess",
        this
      );
    } else {
      AlertModal.showAlert(response.result.message);
    }
  };

  // returnOrderCheckout = response => {
  //   if (response.success === true) {
  //     if (response.result.status === true) {
  //       this.openFeedbackModal();
  //     } else {
  //       AlertModal.showAlert(response.result.message);
  //     }
  //   }
  // };

  returnInternalAccess = (response) => {
    //Disabled survey and just take user back to language selection page as per request of KC and Luis
    /*const language = LanguageUtils.getLangType(this.props);
    if (response.success === true) {
      if (response.result.status === "TRUE") {
        this.openFeedbackModal();
      } else {
        AlertModal.showAlert(response.result.message);
      }
    }*/
    this.props.history.push(ROUTES.LANGUAGE);
  };

  render() {
    const { rating } = this.state;
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <IdleTimerComponent currentPage={"CheckOutSign"} />
        <Header history={this.props.history} />
        <div>
          <div className="body-container">
            <div className="gate-label">
              {LANGCONSTANT[langType].STRINGS.SignatureTitle}
            </div>
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container container-890 white-box">
                    <div className="signature-boundary">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: 808,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={(ref) => (this.signPad = ref)}
                      />
                    </div>

                    <div className="signature-info">
                      <span className="alert-msg">
                        {LANGCONSTANT[langType].STRINGS.SignatureBoundaries}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <a
                        href="javaScript:void(0)"
                        onClick={() => this.openModal()}
                        data-target="#myModal"
                      >
                        <u>
                          {LANGCONSTANT[langType].LABELS.TermsAndConditions}
                        </u>
                      </a>
                      <span className="seal-number">
                        {LANGCONSTANT[langType].LABELS.SealNumber} :
                        {this.state.selectedTrailerDetail.seal_no
                          ? this.state.selectedTrailerDetail.seal_no
                          : ""}
                      </span>
                    </div>
                    <div className="btn-wrapper text-right">
                      <a
                        href="javaScript:void(0)"
                        className="btn btn-submit"
                        onClick={() => this.clear()}
                      >
                        {LANGCONSTANT[langType].LABELS.Erase}
                      </a>
                      <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.back()}
                        value={LANGCONSTANT[langType].BUTTONS.Back}
                      />
                      <input
                        type="button"
                        className="btn btn-submit"
                        onClick={() => this.handlePrint()}
                        value={LANGCONSTANT[langType].BUTTONS.Submit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="progress-bar-bottom">
              <PageProgressComponent
                pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                pageNumber={this.state.pageNumber}
                totalPage={6}
                pageNameList={[
                  LANGCONSTANT[langType].LABELS.ExitGate,
                  LANGCONSTANT[langType].LABELS.ScanGatePass,
                  LANGCONSTANT[langType].LABELS.ConfirmDetails,
                  LANGCONSTANT[langType].LABELS.ScanSeal,
                  LANGCONSTANT[langType].LABELS.Acknowledgement,
                  LANGCONSTANT[langType].LABELS.Sign,
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal">
              <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                {LANGCONSTANT[langType].LABELS.TermsAndConditions}
              </h2>
              <ol>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}
                </li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}
                </li>
              </ol>
              <center>
                <button onClick={() => this.closeModal()}>
                  {LANGCONSTANT[langType].BUTTONS.Ok}
                </button>
              </center>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.feedbackModalIsOpen}
            onAfterOpen={() => this.afterOpenModal()}
            onRequestClose={() => this.closeFeedbackModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal feedback-modal text-center">
              <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                {LANGCONSTANT[langType].STRINGS.CheckoutComplete}
              </h2>
              <p className="feedback-msg">
                {LANGCONSTANT[langType].STRINGS.FeedBackMsg}
              </p>
              <p>
                <b>{LANGCONSTANT[langType].STRINGS.RateExperience}</b>
              </p>
              <div className="rating-stars">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  emptyStarColor={"#ffffff"}
                  value={rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div className="btn-alignment">
                <button onClick={() => this.cancel()}>
                  {LANGCONSTANT[langType].BUTTONS.Cancel}
                </button>
                <button onClick={() => this.submitFeedback()}>
                  {LANGCONSTANT[langType].BUTTONS.Submit}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CheckOutSignComponent__Written.propTypes = {
  dispatch: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckOutSignComponent__Written);
