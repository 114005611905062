import React, { Component } from "react";
import * as guardAction from "../../redux/actions/guardAction";
import * as Routes from "../../constants/routes";
import { connect } from "react-redux";
import * as dataAction from "../../redux/actions/checkoutAction";
import * as pageAction from "../../redux/actions/pageDataAction";
import propTypes from "prop-types";
import Select from "react-select";
import { bindActionCreators } from "redux";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import { getData, setData } from "../../containers/storageHandler";
import AlertModal from "../../common/modals/AlertModal";

class CheckoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facility: "",
      facilityId: "",
      facilityCode: "",
      laneListData: [],
      laneList: "",
      plantType: "",
      skipInbound: "",
      skipLaneSelection: "",
    };
    const pageNameobj = {
      pageName: "configure",
    };
    setData("pageName", pageNameobj);
    this.props.pageActions.pageData(pageNameobj);
  }

  async handleFacilityChange(event) {
    await this.setState({
      facility: event.value,
    });
    this.setLaneData();
  }

  optionData(type) {
    const langType = LanguageUtils.getLangType(this.props);
    var optionData = [];
    if (type === "facility") {
      this.props.data.forEach((data) => {
        optionData.push({
          value: data.org_name,
          label: data.org_name,
          name: "facility",
        });
      });
    } else if (type === "lane") {
      this.state.laneListData.forEach((data) => {
        var dataLaneText = data.lane_text;
        var spanishLaneText = dataLaneText.replace("Lane", "Carril");
        optionData.push({
          value: data.lane_no,
          label: langType === "ESP" ? spanishLaneText : data.lane_text,
          name: "lane",
        });
      });
    }
    return optionData;
  }

  setLaneData() {
    this.props.data.map((name, index) => {
      if (name.org_name === this.state.facility) {
        this.setState({
          laneListData: name.checkout,
          facilityId: name.org_id,
          facilityCode: name.org_code,
          plantType: name.wms_type,
          skipInbound: name.skip_inbound,
          skipLaneSelection: name.skip_lane_selection,
        });
      }
    });
    setData("facilityId", this.state.facilityId);
    setData("facilityCode", this.state.facilityCode);
    let headers = {
      app_version: "1.0.0",
      "Content-Type": "application/json",
    };
  }

  handleSubmit(event) {
    const langType = LanguageUtils.getLangType(this.props);
    event.preventDefault();
    const dataObj = {
      facilityId: this.state.facilityId,
      facilityCode: this.state.facilityCode,
      laneNo: this.state.laneNo,
      laneData: this.state.laneListData,
      plantType: this.state.plantType,
      skipInbound: this.state.skipInbound,
      skipLaneSelection: this.state.skipLaneSelection,
      showFacilityLane: false,
    };
    const guardObj = {
      guard: true,
    };
    if (this.state.facilityId && this.state.laneNo) {
      setData("configureData", dataObj);
      const pageNameobj = {
        pageName: "checkout",
      };
      setData("pageName", pageNameobj);
      setData("guard", true);
      this.props.guardAction.guardData(guardObj);
      this.props.actions.checkoutData(dataObj);
      //this.props.history.push(Routes.EXITGATE);
      this.props.history.push(Routes.LANGUAGE);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  async facilities(event) {
    await this.setState({
      laneNo: event.value,
    });
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <section>
          <div className="tabber-container-wrapper">
            <form
              onSubmit={(eve) => this.handleSubmit(eve)}
              className="white-box container-586"
            >
              <div className="form-group">
                <span>
                  <b>{LANGCONSTANT[langType].LABELS.SelectFacility}</b>
                </span>
                <span>
                  <Select
                    classNamePrefix="custom-select"
                    className="form-control"
                    noOptionsMessage={() =>
                      LANGCONSTANT[langType].STRINGS.NoRecordFound
                    }
                    placeholder={
                      LANGCONSTANT[langType].LABELS.PleaseSelectFacility
                    }
                    name="facility"
                    onChange={(eve) => this.handleFacilityChange(eve)}
                    options={this.optionData("facility")}
                    openMenuOnFocus={true}
                    autoFocus={true}
                  />
                </span>
              </div>
              <div className="form-group">
                <span>
                  <b>{LANGCONSTANT[langType].LABELS.SelectLane}</b>
                </span>
                <span>
                  <Select
                    classNamePrefix="custom-select"
                    className="form-control"
                    noOptionsMessage={() =>
                      LANGCONSTANT[langType].STRINGS.NoRecordFound
                    }
                    placeholder={LANGCONSTANT[langType].LABELS.PleaseSelectLane}
                    name="lane"
                    onChange={(eve) => this.facilities(eve)}
                    options={this.optionData("lane")}
                  />
                </span>
              </div>
              <div className="text-right">
                <input
                  type="submit"
                  name="submit"
                  className="btn btn-submit"
                  value={LANGCONSTANT[langType].LABELS.Configure}
                />
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

CheckoutComponent.propTypes = {
  actions: propTypes.object.isRequired,
  pageActions: propTypes.object.isRequired,
  guardAction: propTypes.object.isRequired,
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch),
    pageActions: bindActionCreators(pageAction, dispatch),
    guardAction: bindActionCreators(guardAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);
