import {
    USERNAME_ERRORS,
    PASSWORD_ERRORS,
    // DRIVER_ERRORS, EXT_ERRORS,
    // OTP_ERRORS, DROP_TRAILER_ERROR,
    // NAME_ERRORS, LICENSE_ERRORS,
    // LANGUAGE_ERRORS, VALUE_ERROR
} from "./constants/validationMessages";
const validator = {
    username: {
        rules: [{
            test: value => {
                return value.trim().length > 0;
            },
            message: USERNAME_ERRORS.blankFieldError
        }
        ],
        errors: [],
        valid: false,
        state: ""
    },
    password: {
        rules: [{
            test: value => {
                return value.length > 0;
            },
            message: PASSWORD_ERRORS.blankFieldError
        }
        ],
        errors: [],
        valid: false,
        state: ""
    }
    // driverName: {
    //     rules: [{
    //             test: value => {
    //                 return value.length > 0;
    //             },
    //             message: DRIVER_ERRORS.blankFieldError
    //         }
    //     ],
    //     errors: [],
    //     valid: false,
    //     state: ""
    // }
}
export {
    validator
};