import React, { Component } from 'react';
import './App.css';
import RouteComponent from './routes/RouteComponent';
import configureStore from './redux/configureStore';
import { Provider as ReduxProvider } from 'react-redux';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <div className="App">
        <ReduxProvider store={store}>
          <RouteComponent />
        </ReduxProvider>
      </div>
    );
  }
}

export default App;
